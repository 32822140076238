import { createStore } from 'redux';

const initialState = {
    sidebarShow: false,
    asideShow: false,
    darkMode: false,
    isLoggedIn: false,
    username: '',
    forename: '',
    surname: '',
    email: '',
    password: '',
    password_confirmation: '',
    registrationResponse: '',
    ad1: '',
    ad2: '',
    town: '',
    county: '',
    country: '',
    postzip: '',
    orders: [],
    shopifyStoreName2: '',
    shopifyAccessToken2: '',
    shopifyStoreName3: '',
    shopifyAccessToken3: '',
    shopifyStoreName4: '',
    shopifyAccessToken4: '',
    shopifyStoreName5: '',
    shopifyAccessToken5: '',
    shopifyStoreName6: '',
    shopifyAccessToken6: '',
    shopifyStoreName7: '',
    shopifyAccessToken7: '',
    shopifyStoreName8: '',
    shopifyAccessToken8: '',
    shopifyStoreName9: '',
    shopifyAccessToken9: '',
    shopifyStoreName10: '',
    shopifyAccessToken10: '',
    cmAppToken: '',
    cmAppSecret: '',
    shopifyInventory: false,
    cmAccessToken: '',
    cmAccessTokenSecret: '',
    manapoolAccessToken: '',
    manapoolEmail: '',
    manapoolConnected: false,
    region: '',
    canScan: false,
    posConfig: [],
    admin: false,
    fbtUsers: [],
    shopifyFilters: [],
    eventModule: [],
    clickanddrop: '',
    shApiKey: '',
    shApiSecret: '',
    shAccessToken: '',
    shInventoryLocation: '',
    ctLinked: false,
    ctToken: '',
    favouriteGame: [],
    allGames: [],
    businessName: '',
    lightspeedDomain: '',
    lightspeedAccessToken: '',
    lightspeedOutletId: '',
    businessName: '',
    businessLogo: '',
    businessWebsite: '',
    cardmarketEnabled: false,
    clickanddropEnabled: false,
    cardtraderEnabled: false,
    shopifyEnabled: false,
    bigCommerceEnabled: false,
    usPricing: false,
    allCMOrders: [],
    allCTOrders: [],
    allSHOrders: [],
    allCTZeroOrders: [],
    ctZOrderCount: 0,
    cmOrderCount: 0,
    shOrderCount: 0,
    ctOrderCount: 0,
    scansAvailable: 0,
    currency: 'GBP',
    taxIdentifier: '',
    taxRates: [],
    distributors: [],
    quicklinks: [],
    platformFees: [],
    cardmarketEmail: "",
    cardmarketPassword: "",
    locations: [],
    profileImg: '',
    walletBalance: 0.0,
    transactions: [],
    lifetimeMember: false,
    isSyncing: false,
    countryOfOrigin: '',
    cmUsername: "",
    automatedPricing: false,
    priceType: "",
    allowedGames: [],
    ebayConfig: {},
    pricePoint: "",
    tcgplayerPublicKey: "",
    tcgplayerPrivateKey: "",
    shopifyStoreKey: "",
    enabledSyncing: false,
};

const addDispatchedProperty = (orders, channel) => orders.map(order => ({ ...order, dispatched: false, channel }));

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set': {
            if (rest.user) {
                rest.cardmarketEnabled = !!rest.user.cardmarketAccessToken;
                rest.clickanddropEnabled = !!rest.user.clickanddrop;
                rest.cardtraderEnabled = !!rest.user.cardtraderJwtToken;
                rest.shopifyEnabled = rest.user.platform === 'Shopify' && !!rest.user.shopifyStoreName;
                rest.bigCommerceEnabled = rest.user.platform === 'Bigcommerce' && !!rest.user.bcStoreHash;
            }

            const newState = { ...state, ...rest };

            const orderChannels = [
                { key: 'orders', channel: 'Cardmarket' },
                { key: 'allCMOrders', channel: 'Cardmarket' },
                { key: 'allCTOrders', channel: 'Cardtrader Direct' },
                { key: 'allSHOrders', channel: 'Shopify' },
                { key: 'allCTZeroOrders', channel: 'Cardtrader Zero' },
            ];

            orderChannels.forEach(({ key, channel }) => {
                if (rest[key]) {
                    newState[key] = addDispatchedProperty(rest[key], channel);
                }
            });

            if (rest.favouriteGame) {
                newState.favouriteGame = state.favouriteGame;
            }

            return newState;
        }
        default:
            return state;
    }
};

const store = createStore(changeState);
export default store;