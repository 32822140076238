import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilSpreadsheet,
  cilHome,
  cilSearch,
  cilSettings,
  cilApplications,
  cilChartPie,
  cibFacebook,
  cibDiscord,
  cibTwitter,
  cibTiktok,
  cilAsteriskCircle,
  cilList,
  cilUser,  // new
  cilUserPlus,
  cilMagnifyingGlass,
  cilAlignLeft,
  cilWarning,
  cilPeople,
  cilWallet,
  cilEnvelopeLetter,
  cilLightbulb,
  cilStorage,
  cilMoney,
  cilAirplaneMode,
  cibShopify,
  cilChatBubble,
  cibDraugiemLv
} from '@coreui/icons'

import { logoNegative } from './logo-negative'


export const icons = Object.assign({}, {
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilSpreadsheet,
  cilHome,
  cilSearch,
  cilSettings,
  cilApplications,
  cilChartPie,
  cibFacebook,
  cibDiscord,
  cibTwitter,
  cibTiktok,
  cilAsteriskCircle,
  cilList,
  cilUser,  // new
  cilUserPlus , // new
  cilMagnifyingGlass,
  cilAlignLeft,
  cilWarning,
  cilPeople,
  cilWallet,
  cilEnvelopeLetter,
  cilLightbulb,
  cilStorage,
  cilMoney,
  cilAirplaneMode,
  cibShopify,
  cilChatBubble,
  cibDraugiemLv
})
