import React, { lazy, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

const TheLayout = lazy(() => import('./containers/TheLayout'));

const App = () => (
    <HashRouter>
        <Suspense fallback={loading}>
            <Switch>
                <Route path="/" name="Dashboard" render={(props) => <TheLayout {...props} />} />
            </Switch>
        </Suspense>
    </HashRouter>
);

export default App;
